#app {
	display: flex;
	flex-flow: column;
	height: 100vh;
	> .wrapper {
		display: block;
		flex: 1 0 auto;
	}
}

a.cover-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
}

button, .btn {
    cursor: pointer;
}

img {
	max-width: 100%;
    height: auto;
}

.locked {
	overflow: hidden !important;
}