#totop {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: ease-in-out 400ms;
    
	.action {
		background-color: #4d1434;
		color: $white;
		display: block;
		height: 40px;
		text-align: center;
		width: 40px;
		line-height: 40px;
		border-radius: 0;
		opacity: 0.4;
        cursor: pointer;
        
        .fa {
            line-height: 38px;
        }
	}
	&.show {
		opacity: 1;
		pointer-events: auto;
	}
}